// function that returns true if value is email, false otherwise
export const verifyEmail = (value: string) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}
// function that verifies if a string has a given length or not
export const verifyLength = (value: string, length: number) => {
  if (value.length >= length) {
    return true
  }
  return false
}

export const verifyPhoneNo = (value: string) => /[0-9]{8,11}/.test(value)

export const verifyBizRegNo = (value: string) => {
  if (/[1-6][0-9]{9}/.test(value)) {
    const parity =
      parseInt(value[0]) * 1 +
      parseInt(value[1]) * 3 +
      parseInt(value[2]) * 7 +
      parseInt(value[3]) * 1 +
      parseInt(value[4]) * 3 +
      parseInt(value[5]) * 7 +
      parseInt(value[6]) * 1 +
      parseInt(value[7]) * 3 +
      parseInt(value[8]) * 5

    return parity === parseInt(value[9])
  } else {
    return false
  }
}
