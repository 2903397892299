/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ImageUpload from 'components/CustomUpload/ImageUpload'
import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Modal,
} from 'reactstrap'
import Config from '../../config'
import { DaumPostcode } from 'react-daum-postcode'
import {
  verifyLength,
  verifyEmail,
  verifyPhoneNo,
  verifyBizRegNo,
} from '../../util/validation.js'
import { useFirebase } from 'react-redux-firebase'

const FormItem = ({
  title,
  fieldName,
  placeholder,
  validFn,
  validity,
  setValidity,
  newProfile,
  setNewProfile,
  focus,
  setFocus,
}) => (
  <FormGroup>
    <label>{title}</label>
    <InputGroup
      className={classnames(validity[fieldName], {
        'input-group-focus': focus[fieldName],
      })}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="nc-icon nc-badge" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        name={fieldName}
        value={newProfile[fieldName]}
        placeholder={placeholder}
        type="text"
        onChange={(e) => {
          setValidity({
            ...validity,
            [fieldName]: validFn(e.target.value) ? 'has-success' : 'has-danger',
          })
          setNewProfile({ ...newProfile, [fieldName]: e.target.value })
        }}
        onFocus={(e) => setFocus({ ...focus, [fieldName]: true })}
        onBlur={(e) => setFocus({ ...focus, [fieldName]: false })}
      />
      {validity[fieldName] === 'has-danger' ? (
        <label className="error">This field is required.</label>
      ) : null}
    </InputGroup>
  </FormGroup>
)

const AddressFormItem = ({
  title,
  fieldName,
  placeholder,
  validFn,
  validity,
  setValidity,
  newProfile,
  setNewProfile,
  focus,
  setFocus,
  setShowDaumPostCode,
}) => (
  <FormGroup>
    <label>{title}</label>
    <InputGroup
      className={classnames(validity[fieldName], {
        'input-group-focus': focus[fieldName],
      })}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="nc-icon nc-badge" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        name={fieldName}
        value={newProfile[fieldName]}
        onClick={() => setShowDaumPostCode(true)}
        placeholder={placeholder}
        type="text"
        onChange={(e) => {
          setValidity({
            ...validity,
            [fieldName]: validFn(e.target.value) ? 'has-success' : 'has-danger',
          })
          setNewProfile({ ...newProfile, [fieldName]: e.target.value })
        }}
        onFocus={(e) => setFocus({ ...focus, [fieldName]: true })}
        onBlur={(e) => setFocus({ ...focus, [fieldName]: false })}
      />
      {validity[fieldName] === 'has-danger' ? (
        <label className="error">This field is required.</label>
      ) : null}
    </InputGroup>
  </FormGroup>
)

function UserProfile() {
  const firebase = useFirebase()
  const profile = useSelector((state) => state.firebase.profile)
  const [newProfile, setNewProfile] = React.useState(profile)
  const [saving, setSaving] = React.useState(false)

  React.useEffect(() => {
    setNewProfile(profile)
    return () => {
      // cleanup
    }
  }, [profile])

  const [validity, setValidity] = React.useState({
    hospitalName: '',
    email: '',
    bizRegNum: '',
    ceoName: '',
    contactName: '',
    bizRegImgFile: '',
    phoneNo: '',
    address: '',
    addressDetail: '',
  })

  const [focus, setFocus] = React.useState({
    hospitalName: '',
    email: '',
    bizRegNum: '',
    ceoName: '',
    contactName: '',
    bizRegImgFile: '',
    phoneNo: '',
    address: '',
    addressDetail: '',
  })

  const [showDaumPostCode, setShowDaumPostCode] = React.useState(false)

  const isValidated = () => {
    if (Object.values(validity).every((value) => 'has-success')) {
      return true
    } else {
      for (const field in validity) {
        if (Object.hasOwnProperty.call(validity, field)) {
          if (validity[field] !== 'has-success')
            setValidity({ ...validity, [key]: 'has-danger' })
        }
      }
      return false
    }
  }

  const saveProfile = async () => {
    try {
      setSaving(true)
      await firebase.updateProfile({ ...newProfile })
    } catch (error) {
      console.error('failed to update profile', error)
    } finally {
      setSaving(false)
    }
  }

  const handleFileChanged = (file) => {
    console.log('file = ', file)
    setNewProfile({ ...newProfile, bizRegImgFile: file })
    setValidity({ ...validity, bizRegImgFile: 'has-success' })
  }

  const handleAddressComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    console.log(fullAddress) // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setNewProfile({ ...newProfile, address: fullAddress })
    setValidity({ ...validity, address: 'has-success' })
    setShowDaumPostCode(false)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">파트너 프로필 수정</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormItem
                        title="기관명"
                        fieldName="hospitalName"
                        placeholder="기관의 공식명칭을 입력해주세요"
                        validFn={(value) => verifyLength(value, 4)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                    <Col className="px-1" md="3">
                      <FormItem
                        title="대표자 성함"
                        fieldName="ceoName"
                        placeholder="대표자 이름을 입력해주세요"
                        validFn={(value) => verifyLength(value, 2)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormItem
                        title="대표 이메일"
                        fieldName="email"
                        placeholder="연락 가능한 이메일 주소를 입력해주세요"
                        validFn={(value) => verifyEmail(value)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormItem
                        title="담당자 성함"
                        fieldName="contactName"
                        placeholder="실무 담당자 성함을 입력해주세요."
                        validFn={(value) => verifyLength(value, 2)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormItem
                        title="대표 전화번호"
                        fieldName="phoneNo"
                        placeholder="연락가능한 전화번호를 입력해주세요. 빈칸없이 숫자만 입력해주세요"
                        validFn={(value) => verifyPhoneNo(value)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <AddressFormItem
                        title="사업장 주소"
                        fieldName="address"
                        placeholder="여기를 눌러서 주소를 입력하세요"
                        validFn={(value) => verifyLength(value, 2)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                        setShowDaumPostCode={setShowDaumPostCode}
                      />
                    </Col>
                    <Col md="4">
                      <FormItem
                        title="상세 주소(건물 동/방번호)"
                        fieldName="addressDetail"
                        placeholder="예) 2층 201호"
                        validFn={(value) => verifyLength(value, 1)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormItem
                        title="사업자등록번호"
                        fieldName="bizRegNum"
                        placeholder="여백이나 기호없이 숫자로만 10자리"
                        validFn={(value) => verifyBizRegNo(value)}
                        validity={validity}
                        setValidity={setValidity}
                        newProfile={newProfile}
                        setNewProfile={setNewProfile}
                        focus={focus}
                        setFocus={setFocus}
                      />
                    </Col>
                    <Col className="px-1" md="8">
                      <FormGroup>
                        <label>사업자등록증 업로드</label>
                      </FormGroup>
                      <ImageUpload
                        url={profile.bizRegImgUrl}
                        selectImageText={Config.i18n('registerUploadBizReg')}
                        onFileChanged={handleFileChanged}
                        changeText={Config.i18n('registerChangePic')}
                        removeText={Config.i18n('registerRemovePic')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>기관 소개</label>
                        <InputGroup
                          className={classnames(validity['aboutUs'], {
                            'input-group-focus': focus['aboutUs'],
                          })}>
                          <Input
                            className="textarea"
                            type="textarea"
                            cols="80"
                            rows="4"
                            value={newProfile.aboutUs || ''}
                            onChange={(e) => {
                              if (e.target.value.length < 201) {
                                setValidity({
                                  ...validity,
                                  aboutUs: 'has-success',
                                })
                                setNewProfile({
                                  ...newProfile,
                                  aboutUs: e.target.value,
                                })
                              } else {
                                setValidity({
                                  ...validity,
                                  aboutUs: 'has-danger',
                                })
                              }
                            }}
                            placeholder="고객들에게 귀 기관을 소개할 수 있는 내용을 입력해주세요 최대 200자"
                          />
                          <div>{newProfile?.aboutUs?.length | 0}/200</div>
                          {validity['aboutUs'] === 'has-danger' ? (
                            <label className="error">
                              200자 이하로 입력해주세요
                            </label>
                          ) : null}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Button color="primary" block onClick={saveProfile}>
                  수정
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={showDaumPostCode}
        toggle={() => setShowDaumPostCode(!showDaumPostCode)}>
        <DaumPostcode
          onComplete={handleAddressComplete}
          autoClose={true}
          animation={true}
        />
      </Modal>
    </>
  )
}

export default UserProfile
