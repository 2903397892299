/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react'
import classnames from 'classnames'

// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Row,
  Col,
  Modal,
} from 'reactstrap'

import ImageUpload from 'components/CustomUpload/ImageUpload'
import { DaumPostcode } from 'react-daum-postcode'

import Config from '../../../config'

const Step2 = React.forwardRef((props, ref) => {
  const [bizRegNum, setBizRegNum] = React.useState('')
  const [ceoName, setCeoName] = React.useState('')
  const [contactName, setContactName] = React.useState('')
  const [bizRegImgFile, setBizRegImgFile] = React.useState(null)
  const [phoneNo, setPhoneNo] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [addressDetail, setAddressDetail] = React.useState('')

  const [bizRegNumState, setBizRegNumState] = React.useState('')
  const [ceoNameState, setCeoNameState] = React.useState('')
  const [contactNameState, setContactNameState] = React.useState('')
  const [bizRegImgFileState, setBizRegImgFileState] = React.useState('')
  const [phoneNoState, setPhoneNoState] = React.useState('')
  const [addressState, setAddressState] = React.useState('')
  const [addressDetailState, setAddressDetailState] = React.useState('')

  const [bizRegNumFocus, setBizRegNumFocus] = React.useState('')
  const [ceoNameFocus, setCeoNameFocus] = React.useState('')
  const [contactNameFocus, setContactNameFocus] = React.useState('')
  const [bizRegImgFileFocus, setBizRegImgFileFocus] = React.useState('')
  const [phoneNoFocus, setPhoneNoFocus] = React.useState('')
  const [addressFocus, setAddressFocus] = React.useState('')
  const [addressDetailFocus, setAddressDetailFocus] = React.useState('')

  const [showDaumPostCode, setShowDaumPostCode] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    isValidated: () => isValidated(),
    state: {
      bizRegNum,
      ceoName,
      contactName,
      bizRegImgFile,
      phoneNo,
      address,
      addressDetail,

      bizRegNumState,
      ceoNameState,
      contactNameState,
      bizRegImgFileState,
      phoneNoState,
      addressState,
      addressDetailState,
    },
  }))

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true
    }
    return false
  }
  const isValidated = () => {
    if (
      bizRegNumState === 'has-success' &&
      ceoNameState === 'has-success' &&
      contactNameState === 'has-success' &&
      bizRegImgFileState === 'has-success' &&
      phoneNoState === 'has-success' &&
      addressState === 'has-success' &&
      addressDetailState === 'has-success'
    ) {
      return true
    } else {
      if (bizRegNumState !== 'has-success') setBizRegNumState('has-danger')
      if (ceoNameState !== 'has-success') setCeoNameState('has-danger')
      if (contactNameState !== 'has-success') setContactNameState('has-danger')
      if (bizRegImgFileState !== 'has-success')
        setBizRegImgFileState('has-danger')
      if (phoneNoState !== 'has-success') setPhoneNoState('has-danger')
      if (addressState !== 'has-success') setAddressState('has-danger')
      if (addressDetailState !== 'has-success')
        setAddressDetailState('has-danger')
      return false
    }
  }

  const handleAddressComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    console.log(fullAddress) // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setAddress(fullAddress)
    setAddressState('has-success')
    setShowDaumPostCode(false)
  }

  const handleFileChanged = (file) => {
    console.log('file = ', file)
    setBizRegImgFile(file)
    setBizRegImgFileState('has-success')
  }

  return (
    <>
      <Modal
        isOpen={showDaumPostCode}
        toggle={() => setShowDaumPostCode(!showDaumPostCode)}>
        <DaumPostcode
          onComplete={handleAddressComplete}
          autoClose={true}
          animation={true}
        />
      </Modal>
      <h5 className="info-text">귀 기관에 대한 정보를 입력해주세요</h5>
      <Row className="justify-content-center">
        <Col lg="10">
          <FormGroup>
            <label>사업자등록번호</label>
            <InputGroup
              className={classnames(bizRegNumState, {
                'input-group-focus': bizRegNumFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="bizRegNum"
                placeholder="사업자 등록번호 대시없이 숫자만 (필수)"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 10)) {
                    setBizRegNumState('has-danger')
                  } else {
                    setBizRegNumState('has-success')
                  }
                  setBizRegNum(e.target.value)
                }}
                onFocus={(e) => setBizRegNumFocus(true)}
                onBlur={(e) => setBizRegNumFocus(false)}
              />
              {bizRegNumState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>대표자 성함</label>
            <InputGroup
              className={classnames(ceoNameState, {
                'input-group-focus': ceoNameFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="ceoName"
                placeholder="홍길동"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 2)) {
                    setCeoNameState('has-danger')
                  } else {
                    setCeoNameState('has-success')
                  }
                  setCeoName(e.target.value)
                }}
                onFocus={(e) => setCeoNameFocus(true)}
                onBlur={(e) => setCeoNameFocus(false)}
              />
              {ceoNameState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>담당자 성함</label>
            <InputGroup
              className={classnames(contactNameState, {
                'input-group-focus': contactNameFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="contactName"
                placeholder="이순신"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 2)) {
                    setContactNameState('has-danger')
                  } else {
                    setContactNameState('has-success')
                  }
                  setContactName(e.target.value)
                }}
                onFocus={(e) => setContactNameFocus(true)}
                onBlur={(e) => setContactNameFocus(false)}
              />
              {contactNameState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>사업자등록증 업로드</label>
            <InputGroup
              className={classnames(bizRegImgFileState, {
                'input-group-focus': bizRegImgFileFocus,
              })}>
              <ImageUpload
                selectImageText={Config.i18n('registerUploadBizReg')}
                onFileChanged={handleFileChanged}
                changeText={Config.i18n('registerChangePic')}
                removeText={Config.i18n('registerRemovePic')}
              />
              {/* <Input
                name="bizRegNum"
                placeholder="사업자 등록번호 (필수)"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 11)) {
                    setBizRegNumState('has-danger')
                  } else {
                    setBizRegNumState('has-success')
                  }
                  setBizRegNum(e.target.value)
                }}
                onFocus={(e) => setBizRegNumFocus(true)}
                onBlur={(e) => setBizRegNumFocus(false)}
              /> */}
              {bizRegImgFileState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>대표 전화</label>
            <InputGroup
              className={classnames(phoneNoState, {
                'input-group-focus': phoneNoFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="phoneNo"
                placeholder="전화번호 대시 공란없이 숫자만"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 10)) {
                    setPhoneNoState('has-danger')
                  } else {
                    setPhoneNoState('has-success')
                  }
                  setPhoneNo(e.target.value)
                }}
                onFocus={(e) => setPhoneNoFocus(true)}
                onBlur={(e) => setPhoneNoFocus(false)}
              />
              {phoneNoState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>사업장 주소</label>
            <InputGroup
              className={classnames(addressState, {
                'input-group-focus': addressFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="address"
                value={address}
                onClick={() => setShowDaumPostCode(true)}
                placeholder="사업장 주소"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 1)) {
                    setAddressState('has-danger')
                  } else {
                    setAddressState('has-success')
                  }
                  setBizRegNum(e.target.value)
                }}
                onFocus={(e) => {
                  setAddressFocus(true)
                }}
                onBlur={(e) => setAddressFocus(false)}
              />
              {addressState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>상세 주소</label>
            <InputGroup
              className={classnames(addressDetailState, {
                'input-group-focus': addressDetailFocus,
              })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="addressDetail"
                placeholder="예) 201호"
                type="text"
                onChange={(e) => {
                  if (!verifyLength(e.target.value, 1)) {
                    setAddressDetailState('has-danger')
                  } else {
                    setAddressDetailState('has-success')
                  }
                  setAddressDetail(e.target.value)
                }}
                onFocus={(e) => setAddressDetailFocus(true)}
                onBlur={(e) => setAddressDetailFocus(false)}
              />
              {addressDetailState === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
})

export default Step2
