/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select'
import _ from 'lodash'

import Config from '../../../config'

export const roleOptions = [
  {
    value: 1,
    label: Config.i18n('providerCategories')[0],
  },
  { value: 2, label: Config.i18n('providerCategories')[1] },
  {
    value: 3,
    label: Config.i18n('providerCategories')[2],
  },
  { value: 4, label: Config.i18n('providerCategories')[3] },
]

// reactstrap components
import { FormGroup, Input, Row, Col, Button } from 'reactstrap'

const Step3 = React.forwardRef((props, ref) => {
  const [invites, setInvites] = React.useState([])
  React.useImperativeHandle(ref, () => ({
    isValidated: undefined,
    state: {
      invites,
    },
  }))

  const onChange = (idx, value) => {
    console.log('invites', invites)
    console.log('idx, value = ', idx, value)
    setInvites([...invites.slice(0, idx), value, ...invites.slice(idx + 1)])
  }

  const onDelete = (idx) => {
    setInvites([...invites.slice(0, idx), ...invites.slice(idx + 1)])
  }

  const addNew = () => {
    setInvites([...invites, { role: 1, name: '', email: '' }])
  }

  const inviteRow = (item, idx) => (
    <Row className="justify-content-center align-items-end">
      <Col sm="3">
        <FormGroup>
          <label>역할</label>
          <Select
            name=""
            className="react-select"
            placeholder=""
            classNamePrefix="react-select"
            value={_.findLast(roleOptions, { value: invites[idx].role })}
            onChange={({ value }) => {
              console.log('select value = ', value)
              onChange(idx, { ...item, role: Number(value) })
            }}
            options={roleOptions}
          />
        </FormGroup>
      </Col>
      <Col sm="3">
        <FormGroup>
          <label>전문가 성함</label>
          <Input
            type="text"
            onChange={(e) => onChange(idx, { ...item, name: e.target.value })}
          />
        </FormGroup>
      </Col>
      <Col sm="4">
        <FormGroup>
          <label>초대 이메일 주소</label>
          <Input
            type="text"
            onChange={(e) => onChange(idx, { ...item, email: e.target.value })}
          />
        </FormGroup>
      </Col>
      <Col sm="1">
        <Button color="danger" onClick={() => onDelete(idx)}>
          Del
        </Button>
      </Col>
    </Row>
  )
  return (
    <>
      <Row className="justify-content-center">
        <Col sm="12">
          <h5 className="info-text">귀 기관 소속 전문가들을 초대해주세요</h5>
        </Col>
      </Row>
      {invites.map((item, idx) => inviteRow(item, idx))}
      <Row className="justify-content-center">
        <Col sm="5">
          <Button block onClick={addNew} color="primary">
            Add
          </Button>
        </Col>
      </Row>
    </>
  )
})

export default Step3
