import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap'
import dayjs from 'dayjs'

import ReactTable from 'components/ReactTable/ReactTable.js'
import InviteModal from './InviteModal'
import { generateVerifyCode } from 'util/Math'
import { buildLink } from 'util/dynamicLink'

export default function Clients() {
  const [dataState, setDataState] = React.useState([])
  const [modalVisible, setModalVisible] = React.useState(false)
  const [currentInvite, setCurrentInvite] = React.useState(null)
  const firestore = useFirestore()

  const auth = useSelector((state) => state.firebase.auth)
  const profile = useSelector((state) => state.firebase.profile)
  console.log('auth = ', auth)
  console.log('profile = ', profile)

  useFirestoreConnect([
    {
      collection: `clinics/${auth.uid}/invites`,
      storeAs: 'invites',
    },
  ])

  const invites = useSelector((state) => state.firestore.ordered.invites)
  console.log('invites = ', invites)

  const toggleModal = () => setModalVisible(!modalVisible)

  useEffect(() => {
    if (!invites) {
      setDataState([])
    } else {
      setDataState(
        invites?.map(
          ({ id, email, name, verifyCode, verified, invitedAt }, key) => {
            return {
              id,
              name,
              email,
              verifyCode,
              verified,
              invitedAt,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a like kind of action */}
                  <Button
                    onClick={() => {
                      let obj = dataState.find((o) => o.id === key)
                      alert(
                        "You've clicked LIKE button on \n{ \nName: " +
                          obj.name +
                          '\n}.',
                      )
                    }}
                    color="info"
                    size="sm"
                    className="btn-icon btn-link like">
                    <i className="fa fa-heart" />
                  </Button>{' '}
                  {/* use this button to add a edit kind of action */}
                  <Button
                    onClick={() => {
                      let obj = dataState.find((o) => o.id === key)
                      alert(
                        "You've clicked EDIT button on \n{ \nName: " +
                          obj.name +
                          ', \nposition: ' +
                          '\n}.',
                      )
                    }}
                    color="warning"
                    size="sm"
                    className="btn-icon btn-link edit">
                    <i className="fa fa-edit" />
                  </Button>{' '}
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      var data = dataState
                      data.find((o, i) => {
                        if (o.id === key) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          data.splice(i, 1)
                          console.log(data)
                          return true
                        }
                        return false
                      })
                      setDataState(data)
                    }}
                    color="danger"
                    size="sm"
                    className="btn-icon btn-link remove">
                    <i className="fa fa-times" />
                  </Button>{' '}
                </div>
              ),
            }
          },
        ),
      )
    }

    return () => {
      // cleanup
    }
  }, [invites])

  const saveInvite = async () => {
    console.log('save invite = ', currentInvite)
    const batch = firestore.batch()
    const verifyCode = generateVerifyCode()
    try {
      batch.set(firestore.collection(`clinics/${auth.uid}/invites`).doc(), {
        ...currentInvite,
        verified: false,
        verifyCode,
        invitedAt: firestore.FieldValue.serverTimestamp(),
      })

      const link = 'https://gideb.page.link/R6GT'

      batch.set(firestore.collection('mail').doc(), {
        to: [currentInvite.email],
        message: {
          subject: '기댑의 전문가로 초대합니다',
          text: `안녕하세요. 기댑입니다. ${profile.hospitalName}에서 귀하를 기댑의 상담 전문가 그룹으로 초대하셨습니다.\n 이 링크를 눌러서 앱을 다운로드하고,\n 앱 다운로드: ${link}\n 전문가 버튼을 눌러 회원가입을 하십시오. 이 때 초대코드를 다음과 같이 입력해주세요.\n초대코드: ${verifyCode}`,
        },
      })

      await batch.commit()
      setCurrentInvite(null)
      toggleModal()
      alert('초대했습니다.')
    } catch (e) {
      alert('초대하지 못했습니다')
      console.error('failed to saveInvite ', e)
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col>준비중</Col>
        </Row>
      </div>
      {/* <InviteModal
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        saveInvite={saveInvite}
        setCurrentInvite={setCurrentInvite}
        currentInvite={currentInvite}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">고객리스트(준비중)</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      className="pull-right"
                      onClick={toggleModal}>
                      신규 초대
                    </Button>
                  </Col>
                </Row>
                <ReactTable
                  data={dataState}
                  columns={[
                    {
                      Header: '이름',
                      accessor: 'name',
                    },
                    {
                      Header: '이메일',
                      accessor: 'email',
                    },
                    {
                      Header: '인증코드',
                      accessor: 'verifyCode',
                    },
                    {
                      id: 'verified',
                      Header: '상태',
                      accessor: (d) => (d.verified ? '완료' : '미완료'),
                    },
                    {
                      id: 'invitedAt',
                      Header: '초대일시',
                      accessor: (d) =>
                        dayjs(d.invitedAt?.seconds * 1000).format('YYYY-MM-DD'),
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div> */}
    </>
  )
}
