import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from '../layouts/Auth'
import AdminLayout from '../layouts/Admin'
import { useFirebase } from 'react-redux-firebase'

import { useDispatch } from 'react-redux'

const hist = createBrowserHistory()

const RouteProvider = () => {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const onChangeUser = async (authUser) => {
    if (authUser) {
      const idTokenResult = await authUser.getIdTokenResult()
      console.log('idTokenResult = ', idTokenResult)

      hist.push('/admin/dashboard')
    } else {
      hist.push('/auth/login')
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(onChangeUser)
    return () => {
      // cleanup
    }
  }, [])
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect to="/admin/dashboard" />
      </Switch>
    </Router>
  )
}

export default RouteProvider
