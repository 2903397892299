import React from 'react'
import { Provider } from 'react-redux'

import { createStore, combineReducers, compose, applyMiddleware } from 'redux'

// firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

// redux firebase
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from 'react-redux-firebase'
// needed if using firestore
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

// redux thunk
import { getFirebase } from 'react-redux-firebase'
import thunk from 'redux-thunk'
import UserReducer from './UserReducer'

require('dotenv').config()

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  mesuarementId: process.env.REACT_APP_MEASUREMENT_ID,
}
firebase.initializeApp(config)

const rrfConfig = {
  userProfile: 'clinics',
  useFirestoreForProfile: true,
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: UserReducer,
})

const initialState = {}

const middlewares = [thunk.withExtraArgument(getFirebase)]
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares),
    ...(window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : []),
  ),
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

export default function RRFPrvider({ children }) {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}
