export default {
  en: {
    // REGISTER
    registerNickname: 'Nickname',
    registerEmail: 'Email',
    registerPassword:
      'Password(Must contain uppercase, lowercase, special, number and be 8 characters long)',
    registerConfirmPassword: 'Confirm Password',
    registerFirstName: 'First Name',
    registerLastName: 'Last Name',
    registerAddress: 'Address',
    registerAreaExpertise: 'Area of Expertise',
    registerAreaOfExpertiseOptions: [
      {
        label: 'Mental Health Medical Clinic',
        value: 'Mental Health Medical Clinic',
      },
      { label: 'Psychological Counseling', value: 'Psychological Counseling' },
      {
        label: 'Psychological Counseling Center',
        value: 'Psychological Counseling Center',
      },
      { label: 'Other....', value: 'Other....' },
    ],
    registerMessage: {
      onEmailDuplication: 'Email is duplicated',
      onNicknameDuplicatation: 'Nickname is duplicated',
      onEmailSuccess: 'Nice email!',
      onNicknameSuccess: 'Nice nickname!',
      onInvalidEmail: 'Please input valid email',
      onInvalidNickname: 'Nickname should be 5 letters or more',
    },
    registerTermsTitle: '(Please agree to the terms and conditions.)',
    registerTerms1: 'Agree to the full terms and conditions',
    registerTerms2: 'Agree to the terms of use of Descry Inc. (required)',
    registerTerms3:
      'Agree to collection and use of personal information (required)',
    registerPhoneNumberVerify: 'Phone Number Verification',
    registerCEOName: 'CEO Name',
    registerHospitalName: 'Hospital/Clinic Name',
    registerBizRegNum: 'Business Registration Number',
    registerContactName: 'Contact Name',
    registerPhoneNumber: 'Phone Number',
    registerButtonText: 'Register',
    registerUploadBizReg: 'Upload Business License',
    registerChangePic: 'Change',
    registerRemovePic: 'Remove',
    // USER PROFILE
    userProfileAddressLabel: 'Address',
    userProfileAreaOfExpertiselabel: 'Area of Expertise',
    userProfileBizRegNumLabel: 'Business Registration Number',
    userProfileCeoNameLabel: 'CEO Name',
    userProfileContactNameLabel: 'Contact Name',
    userProfileEmailLabel: 'Email',
    userProfileHospitalNameLabel: 'Hospital Name',
    userProfileBusinessRegImgLabel: 'Business Registration',
    userProfilePhoneNumberLabel: 'Phone Number',
    userProfileYourProviders: 'Your Providers',
    userProfileEditProfile: 'Edit Profile',
    userProfileEditText: 'Edit',
    userProfileSaveText: 'Save',
    userProfileProviderNameLabel: 'Provider Name',
    footerDescryCeoName: 'Descry Co. CEO Tae Yang Kim',
    footerAddress:
      'Jongno-gu Changgyeonggung-ro 35-gil 26 5th Floor Seoul, South Korea Business Registration No.: 803-87-01466 Chief Information Officer: Yeonha Lee',
    footerLongText:
      'Descry Co., provides raising awareness and convenience of mental health care in the Korean mental health market by increasing the accessibility between clients, hospitals, and counseling agencies. Descry Co. | Gideb, is not involved or responsible in the medical consultation contents and counseling of general members, and the selection of hospitals and counseling agencies, and complies with medical law and other relevant regulations. We do not assume any legal responsibility for the medical services and counseling provided to each member by each hospital and counseling agency. It also provides a reservation and online information system between members and is not a party to the mail order. All medical consultations and medical services are performed directly by each hospital and counseling agency, and all hospitals and counseling agencies provide independent medical counseling and medical services. In addition, there is no organizational relationship between Descry Co., and the clinicians and counseling agencies.',
    footerContact: 'Contact for Questions 02.6082.6500 tech@gideb.com',
    footerAllRightsReserved: 'Descry Co. All rights reserved.',
    providerCategories: ['전문의', '상담사', '담당자', '기타'],
  },
  ko: {
    // REGISTER
    registerNickname: '닉네임',
    registerEmail: '이메일',
    registerPassword:
      '비민번호(Must contain uppercase, lowercase, special, number and be 8 characters long)',
    registerConfirmPassword: '비밀번호 확인',
    registerFirstName: '이름',
    registerLastName: '성',
    registerAddress: '주소',
    registerAreaExpertise: '치료기관 부분',
    registerAreaOfExpertiseOptions: [
      { label: '정신건강의학과병(의)원', value: '정신건강의학과병(의)원' },
      { label: '심리전문상담', value: '심리전문상담' },
      { label: '심리상담센터', value: '심리상담센터' },
      { label: '기타...', value: '기타...' },
    ],
    registerMessage: {
      onEmailDuplication: '이메일이 이미 가입되어 있습니다',
      onNicknameDuplicatation: '닉네임이 이미 사용 중입니다',
      onEmailSuccess: '좋은 이메일이네요!',
      onNicknameSuccess: '좋은 닉네임입니다!',
      onInvalidEmail: '정확한 이메일 주소를 입력해주세요',
      onInvalidNickname: '닉네임은 다섯글자 이상되어야 합니다.',
    },
    registerTermsTitle: '(약관에 동의해야 가입이 가능합니다.)',
    registerTerms1: '전체 약관에 동의합니다',
    registerTerms2: '(주)디스크라이 이용약관에 동의 (필수)',
    registerTerms3: '개인정보 수집 및 이용에 대한 안내 (필수)',
    registerPhoneNumberVerify: '전화번호 인증',
    registerCEOName: '대표자 명',
    registerHospitalName: '병·의원(상담기관) 명',
    registerBizRegNum: '사업자등록번호',
    registerContactName: '담당자',
    registerPhoneNumber: '전화번호',
    registerButtonText: '가입하기',
    registerUploadBizReg: '사업자등록증 올리기',
    registerChangePic: '변화',
    registerRemovePic: '지우다',
    // USER PROFILE
    userProfileAddressLabel: '주소',
    userProfileAreaOfExpertiselabel: '치료기관 부분',
    userProfileBizRegNumLabel: '사업자등록번호(XXX-XX-XXXXX)',
    userProfileCeoNameLabel: '대표자 명',
    userProfileContactNameLabel: '담당자',
    userProfileEmailLabel: '이메일',
    userProfileHospitalNameLabel: '병·의원(상담기관) 명',
    userProfileBusinessRegImgLabel: '사업자등록증',
    userProfilePhoneNumberLabel: '전화번호(XXX-XXXX-XXXX | XX-XXX-XXXX)',
    userProfileYourProviders: '우리 의사님들',
    userProfileEditProfile: '프로필 수정',
    userProfileEditText: '수정',
    userProfileSaveText: '저장',
    userProfileProviderNameLabel: '위사의사의 이름명',
    footerDescryCeoName: '(주)디스크라이 | 대표 김 태 양',
    footerAddress:
      '서울특별시 종로구 창경궁로 35길 26, 5층 (혜화동) 사업자 등록번호 : 803-87-01466 개인정보책임 담당자 | 이 연 하',
    footerLongText:
      '주식회사 디스크라이는 대한민국 정신건강 의료시장에서 고객과 병⦁의원 및 상담기관 간의 접근성을 높여 정신건강 진료의 대중화를 위한 서비스 ‘기댑’ 을 제공하고 있습니다. 주식회사 디스크라이와 기댑 서비스는 일반회원의 의료 상담 내용 및 상담 여부, 병의원 및 상담기관의 선택 등에 대해 일절 관여하지 않아 의료법 및 기타 관련 규정을 준수하고 있습니다. 각 병⦁의원 및 상담기관이 일반회원에게 제공하는 의료 서비스 및 상담에 대해 어떠한 법적 책임도 부담하지 않습니다. 또한 회원 간의 예약 및 온라인 정보 시스템을 제공할 뿐, 통신판매의 당사자가 아닙니다. 모든 의료 상담 및 의료 서비스는 각 병⦁의원 및 상담기관이 직접 수행하며, 모든 병⦁의원 및 상담기관은 독립적으로 의료 상담 및 의료 서비스를 제공합니다. 그리고 디스크라이와 기댑서비스와 가입한 각 병⦁의원 및 상담기관들 간에는 어떠한 조직적인 관계가 없음을 밝힙니다.',
    footerContact: '문의사항 02.6082.6500 tech@gideb.com',
    footerAllRightsReserved: '2020 (주)디스크라이. All rights reserved.',
    providerCategories: ['전문의', '상담사', '담당자', '기타'],
  },
}
