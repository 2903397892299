/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'

import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'

function Login() {
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.toggle('login-page')
    return function cleanup() {
      document.body.classList.toggle('login-page')
    }
  })

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  React.useEffect(() => {
    setErrorMessage('')
    return () => {
      // cleanup
    }
  }, [email, password])

  const firebase = useFirebase()

  const submit = async () => {
    console.log('email = ', email)
    console.log('password = ', password)
    try {
      const user = await firebase.login({ email, password })
      console.log('user = ', user)
    } catch (error) {
      console.error('login failed', error)
      setErrorMessage(error.message)
    }
  }

  const goToRegister = () => {
    history.push('/auth/register')
  }

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h4 className="header text-center">
                      기댑 파트너로 로그인하세요
                    </h4>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="이메일"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="비밀번호"
                      type="password"
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>

                  {/* <br />
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        Subscribe to newsletter
                      </Label>
                    </FormGroup>
                  </FormGroup> */}
                  <Row>
                    <Col>
                      {errorMessage ? (
                        <div className="text-danger text-center">
                          {errorMessage}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    href="#pablo"
                    onClick={submit}>
                    파트너 로그인
                  </Button>
                  <div>또는</div>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    outline
                    onClick={goToRegister}>
                    파트너 가입
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${
            require('assets/img/bg/fabio-mangione.jpg').default
          })`,
        }}
      />
    </div>
  )
}

export default Login
