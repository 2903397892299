/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import classnames from 'classnames'
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap'

// core components

const Step1 = React.forwardRef((props, ref) => {
  const [hospitalName, sethospitalName] = React.useState('')
  const [password, setpassword] = React.useState('')
  const [email, setemail] = React.useState('')
  const [hospitalNameState, sethospitalNameState] = React.useState('')
  const [passwordState, setpasswordState] = React.useState('')
  const [emailState, setemailState] = React.useState('')
  const [hospitalNameFocus, sethospitalNameFocus] = React.useState('')
  const [passwordFocus, setpasswordFocus] = React.useState('')
  const [emailFocus, setemailFocus] = React.useState('')
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
    state: {
      hospitalName,
      password,
      email,
      hospitalNameState,
      passwordState,
      emailState,
    },
  }))
  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true
    }
    return false
  }
  const isValidated = () => {
    if (
      hospitalNameState === 'has-success' &&
      passwordState === 'has-success' &&
      emailState === 'has-success'
    ) {
      return true
    } else {
      if (hospitalNameState !== 'has-success') {
        sethospitalNameState('has-danger')
      }
      if (passwordState !== 'has-success') {
        setpasswordState('has-danger')
      }
      if (emailState !== 'has-success') {
        setemailState('has-danger')
      }
      return false
    }
  }
  return (
    <>
      <h5 className="info-text">
        파트너 계정은 바꿀 수 없으니 신중하게 생성하세요
        <br />
        메일 수신이 가능한 유효한 이메일 주소를 입력해주세요
      </h5>
      <Row className="justify-content-center">
        <Col lg="10">
          <InputGroup
            className={classnames(hospitalNameState, {
              'input-group-focus': hospitalNameFocus,
            })}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-badge" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="hospitalName"
              placeholder="기관명 (필수)"
              type="text"
              onChange={(e) => {
                if (!verifyLength(e.target.value, 4)) {
                  sethospitalNameState('has-danger')
                } else {
                  sethospitalNameState('has-success')
                }
                sethospitalName(e.target.value)
              }}
              onFocus={(e) => sethospitalNameFocus(true)}
              onBlur={(e) => sethospitalNameFocus(false)}
            />
            {hospitalNameState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>

          <InputGroup
            className={classnames(emailState, {
              'input-group-focus': emailFocus,
            })}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-email-85" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="email"
              placeholder="이메일 주소 (필수)"
              type="email"
              onChange={(e) => {
                if (!verifyEmail(e.target.value)) {
                  setemailState('has-danger')
                } else {
                  setemailState('has-success')
                }
                setemail(e.target.value)
              }}
              onFocus={(e) => setemailFocus(true)}
              onBlur={(e) => setemailFocus(false)}
            />
            {emailState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
          <InputGroup
            className={classnames(passwordState, {
              'input-group-focus': passwordFocus,
            })}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              name="password"
              placeholder="비밀번호 (필수)"
              onChange={(e) => {
                if (!verifyLength(e.target.value, 4)) {
                  setpasswordState('has-danger')
                } else {
                  setpasswordState('has-success')
                }
                setpassword(e.target.value)
              }}
              onFocus={(e) => setpasswordFocus(true)}
              onBlur={(e) => setpasswordFocus(false)}
            />
            {passwordState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
        </Col>
      </Row>
    </>
  )
})

export default Step1
