import React from 'react'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Button,
} from 'reactstrap'
import Select from 'react-select'

import { roleOptions } from '../pages/WizardSteps/Step3'

export default function InviteModal({
  currentInvite,
  setCurrentInvite,
  saveInvite,
  toggleModal,
  modalVisible,
}) {
  return (
    <Modal isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>초대하기</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <label>역할</label>
              <Select
                name=""
                className="react-select"
                placeholder=""
                classNamePrefix="react-select"
                value={_.findLast(roleOptions, { value: currentInvite?.role })}
                onChange={({ value }) => {
                  console.log('select value = ', value)
                  setCurrentInvite({ ...currentInvite, role: Number(value) })
                }}
                options={roleOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>전문가 성함</label>
              <Input
                type="text"
                onChange={(e) =>
                  setCurrentInvite({ ...currentInvite, name: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>초대 이메일 주소</label>
              <Input
                type="text"
                onChange={(e) =>
                  setCurrentInvite({ ...currentInvite, email: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={saveInvite}>
          저장
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          취소
        </Button>
      </ModalFooter>
    </Modal>
  )
}
