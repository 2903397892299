/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'

// reactstrap components
import { Container, Row, Col } from 'reactstrap'

import ReactWizard from 'react-bootstrap-wizard'

// wizard steps
import Step1 from './WizardSteps/Step1.js'
import Step2 from './WizardSteps/Step2.js'
import Step3 from './WizardSteps/Step3.js'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { generateVerifyCode } from 'util/Math.js'
import { buildLink } from 'util/dynamicLink.js'

var steps = [
  {
    stepName: 'step1',
    stepIcon: 'nc-icon nc-bank',
    component: Step1,
    stepProps: {
      title: '계정 설정',
    },
  },
  {
    stepName: 'step2',
    stepIcon: 'nc-icon nc-touch-id',
    component: Step2,
    stepProps: {
      title: '기관 정보',
    },
  },
  {
    stepName: 'step3',
    stepIcon: 'nc-icon nc-trophy',
    component: Step3,
    stepProps: {
      title: '전문가 초대',
    },
  },
]

const defaultUser = {
  email: undefined,
  password: undefined,
  confirmPassword: undefined,
  hospitalName: undefined,
  ceoName: undefined,
  bizRegNum: undefined,
  contactName: undefined,
  phoneNo: undefined,
  address: undefined,
  addressDetail: undefined,
  areaOfExpertise: undefined,
  imgUrl: undefined,
}

function Register() {
  React.useEffect(() => {
    document.body.classList.toggle('register-page')
    return function cleanup() {
      document.body.classList.toggle('register-page')
    }
  })
  const [agreements, setAgreements] = React.useState([false, false, false])
  const [isLoading, setIsLoading] = React.useState(false)
  const firebase = useFirebase()
  const firestore = useFirestore()

  const onFinish = async (allStates) => {
    if (isLoading) return
    setIsLoading(true)

    const {
      step1: { email, password, hospitalName },
      step2: {
        bizRegNum,
        ceoName,
        contactName,
        bizRegImgFile,
        phoneNo,
        address,
        addressDetail,
      },
      step3: { invites },
    } = allStates

    let bizRegImgUrl = null
    let bizRegImgPath = null

    try {
      if (bizRegImgFile) {
        const { uploadTaskSnapshot } = await firebase.uploadFile(
          `/partners/bizCerts/${bizRegNum}`,
          bizRegImgFile,
        )
        if (uploadTaskSnapshot) {
          bizRegImgUrl = await uploadTaskSnapshot.ref.getDownloadURL()
          bizRegImgPath = uploadTaskSnapshot.ref.fullPath
        }
      }

      const partner = {
        email,
        hospitalName,
        ceoName,
        bizRegNum,
        bizRegImgUrl,
        bizRegImgPath,
        contactName,
        phoneNo,
        address,
        addressDetail,
      }
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      console.log('user created! ', user)
      if (!user.uid) {
        throw 'uid is required'
      }
      const batch = firestore.batch()
      batch.set(firestore.doc(`clinics/${user.uid}`), partner)
      if (invites.length > 0) {
        invites.map(({ email, name, role }) => {
          const verifyCode = generateVerifyCode()
          batch.set(firestore.collection(`clinics/${user.uid}/invites`).doc(), {
            email,
            name,
            verified: false,
            role,
            verifyCode,
            invitedAt: firestore.FieldValue.serverTimestamp(),
          })
          const link = 'https://gideb.page.link/R6GT'
          // buildLink(
          //   `https://gideb.com/providers/invites?code=${verifyCode}`,
          // )
          batch.set(firestore.collection(`mail`).doc(), {
            to: [email],
            message: {
              subject: '기댑의 전문가로 초대합니다',
              text: `안녕하세요. 기댑입니다. ${hospitalName}에서 귀하를 기댑의 상담 전문가 그룹으로 초대하셨습니다.\n 이 링크를 눌러서 앱을 다운로드하고,\n 앱 다운로드: ${link}\n 전문가 버튼을 눌러 회원가입을 하십시오. 이 때 초대코드를 다음과 같이 입력해주세요.\n초대코드: ${verifyCode}`,
            },
          })
        })
      }
      batch.commit()
    } catch (error) {
      console.error('failed to register partner: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="register-page">
      <Container>
        <Row>
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="파트너 프로필을 생성하세요"
              description={
                '파트너로 등록하시면 정신건강에 관심이 있는 기댑 사용자와 연결됩니다.'
              }
              headerTextCenter
              finishButtonClasses="btn-wd"
              nextButtonClasses="btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonClick={onFinish}
            />
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${
            require('assets/img/bg/jan-sendereks.jpg').default
          })`,
        }}
      />
    </div>
  )
}

export default Register
