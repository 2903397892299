const initialState = {
  uid: null,
  displayName: null,
  photoURL: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return { ...state, ...payload }

    default:
      return state
  }
}
